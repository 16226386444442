<template>
  <component
    v-if="iconComponent && !iconComponent.custom"
    :is="iconComponent"
    color="#bfbfc0"
    :size="16"
  />
  <v-img
    v-else-if="iconComponent"
    :style="{ maxWidth: '16px', maxHeight: '16px' }"
    :src="require(`@/assets/${iconComponent.name}.svg`)"
  />
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      required: false,
      default: "",
    },
  },
  data() {
    return {
      iconComponent: null,
    };
  },
  async mounted() {
    this.componentLoader();
  },
  watch: {
    "$store.state.wallet.activeFilter": function () {
      this.componentLoader();
    },
  },
  computed: {
    icon() {
      let typeIcon = "";
      switch (this.type.toLowerCase()) {
        case "cinema":
          typeIcon = "PhPopcorn";
          break;
        case "social media":
          typeIcon = "PhUsersFour";
          break;
        case "report inappropriate content":
          typeIcon = "PhWarningCircle";
          break;
        case "communication":
          typeIcon = "PhChatsCircle";
          break;
        case "tatatu partners":
          typeIcon = "PhHandshake";
          break;
        case "rewards":
          typeIcon = "PhArrowCounterClockwise";
          break;
        case "user transfers":
          typeIcon = "PhUser";
          break;
        case "referrals":
          typeIcon = "PhUsersThree";
          break;
        case "donations":
          typeIcon = {
            custom: true,
            name: "donations",
          };
          break;
        case "e-commerce":
          typeIcon = "PhShoppingCart";
          break;
        case "auctions":
          typeIcon = {
            custom: true,
            name: "auctions",
          };
          break;
        case "redeemed discounts":
          typeIcon = "PhHourglassMedium";
          break;
        default:
          typeIcon = "PhHeart";
      }
      return typeIcon;
    },
  },
  methods: {
    async componentLoader() {
      if (this.icon) {
        let res = await (() => import("phosphor-vue"))``;
        if (this.icon.custom) {
          this.iconComponent = this.icon;
        } else {
          this.iconComponent = res[this.icon];
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .v-image__image {
  background-size: contain;
}
</style>
